import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Matrix } from 'src/app/interfaces/Class/Stadium/matrix';
import { Cart } from 'src/app/interfaces/State/Cart/cart';
import { CommonState } from 'src/app/interfaces/State/Common/common-state';
import { SoldSeats } from 'src/app/interfaces/State/Seat/sold-seats';
import { Seat } from 'src/app/interfaces/State/Stadium/seat';
import { Sector } from 'src/app/interfaces/State/Stadium/sector';
import { SectorWithSeats } from 'src/app/interfaces/State/Stadium/sector-with-seats';
import { StadiumWithSectorsAndSeats } from 'src/app/interfaces/State/Stadium/stadium-with-sectors-and-seats';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CartService } from 'src/app/services/Cart/cart.service';
import { SeatModule } from './_partials/seat/seat.module';
import { PurchaseType } from 'src/app/enums/stadium/purchase-type';
import { User } from 'src/app/interfaces/State/User/user';
import { UserType } from 'src/app/enums/user/userType';
import { SectorTypes } from 'src/app/enums/stadium/sector/sectorTypes';
import { Modals } from 'src/app/interfaces/State/Modal/modals';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { SectorData } from 'src/app/interfaces/State/Sector/sector-data';
import { DeletingSeat } from 'src/app/interfaces/State/Seat/deleting-seat';
import { SectorBoundaries } from 'src/app/interfaces/State/Sector/sector-boundaries';
import { SeatDetails } from 'src/app/interfaces/State/Seat/seat-details';
import { TranslateService } from '@ngx-translate/core';
import { BookSeatData } from 'src/app/interfaces/State/Seat/book-seat-data';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stadium-holder',
  templateUrl: './stadium.component.html',
  styleUrls: ['./stadium.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StadiumComponent implements OnInit {
  @Input() scale: number = 1
  @Input() commonState: CommonState = {} as CommonState
  @Input() stadiumState: StadiumWithSectorsAndSeats = {} as StadiumWithSectorsAndSeats
  @Input() soldSeats: SoldSeats = {}
  @Input() deletingSeats: DeletingSeat = {}
  @Input() seatDetails: SeatDetails = {}
  @Input() sectorData: SectorData = {} as SectorData
  @Input() fixtureId: string = ''
  @Input() seasonPassId: string = ''
  @Input() flexId: string = ''
  @Input() cart: Cart = {} as Cart
  @Input() canInteractWithSeat: boolean = true
  @Input() user: User = {} as User
  @Input() purchaseType: PurchaseType = PurchaseType.TICKET
  @Input() scaleThreshold: number = 6
  @Output() sectorClickedEvent: EventEmitter<string> = new EventEmitter<string>();

  public isRequesting: { [seatId: string]: boolean } = {}
  public isRequestBlocked: boolean = false

  constructor(
    private _cartService: CartService,
    private _alertService: AlertService,
    private _cdr: ChangeDetectorRef,
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
  }

  /*
  * returns if the seat is sold
  */
  public isSeatSold(seatId?: string): boolean {   
    return seatId && this.isSeatSoldBySoldSeats(seatId) ? true : false
  }

  private isSeatSoldBySoldSeats(seatId: string): boolean 
  {
    return this.soldSeats && this.soldSeats[seatId] !== null&&
    (['n', 'csbc', 'b'].includes(this.soldSeats[seatId]) || 
    (!this.isCashier() && this.soldSeats[seatId] == 'c'))
  }

  /*
  * returns if the seat is selectable for cashier AND closed for public
  */
  public isClosedForPublicButSelectableForCashier(seatId?: string): boolean {
    return seatId && this.soldSeats && this.soldSeats[seatId] && this.isCashier() && this.soldSeats[seatId] == 'c' ? true : false
  }

  public isSectorSelectableForCashier(sector: SectorWithSeats) 
  {
    return this.isCashier() && (this.sectorData[sector.i as keyof SectorData]?.status == 'closed')
  }

  /* 
  * returns if the seat is in my cart 
  */
  public isSeatInMyCart(seatId?: string): boolean {
    return seatId && this.cart?.items &&
      (this.isTicketInMyCart(seatId) || this.isSeasonPassInMyCart(seatId) || this.isFlexTicketInMyCart(seatId))

      ? true : false
  }

  /*
  * returns if the ticket is in my cart
  */
  private isTicketInMyCart(seatId?: string) {
    return seatId && this.cart.items.events[this.fixtureId] && this.cart.items.events[this.fixtureId].tickets[seatId] ? true : false
  }

  /*
  * returns if the season pass is in my cart
  */
  private isSeasonPassInMyCart(seatId?: string) {
    return seatId && this.cart.items.season_passes[this.seasonPassId] && this.cart.items.season_passes[this.seasonPassId].season_passes[seatId] ? true : false
  }

  private isFlexTicketInMyCart(seatId?: string) {
    return seatId && this.cart.items.flex_tickets[this.flexId] && this.cart.items.flex_tickets[this.flexId].flex_tickets[seatId] ? true : false
  }

  public isSectorClosed(sector: SectorWithSeats): boolean {
    return (!this.isCashier() && this.sectorData[sector.i as keyof SectorData]?.status == 'closed')
    || (this.sectorData[sector.i as keyof SectorData]?.status == 'blocked')
  }

  public getSectorFill(sector: SectorWithSeats): string {
    if (this.isSectorClosed(sector)) {
      return 'var(--red)';
    }

    return this.getNumberOfTicketsInSector(sector.i) ? '#1F62EA' : '#E1E1E1'
  }

  /*
  * handles click on seats
  */
  public seatClicked(seat: any, sector: SectorWithSeats): void {
    if (this.isRequesting[seat.id] || !this.canInteractWithSeat || this.isRequestBlocked) return;

    if (this.isSectorClosed(sector)) return;

    this.isRequestBlocked = true

    /* if the seat is not in the sold seats */
    if (!this.isSeatSold(seat.id)) {
      this._bookSeat(seat.id)
      return;
    }

    /* if the seat is in my cart then remove the seat */
    if (this.isSeatInMyCart(seat.id)) {
      this._removeSeat(seat.id)
      return;
    }

    this.isRequestBlocked = false
    return;
  }

  private isCashier() {
    return this.user.type == UserType.CASHIER
  }

  getArray(length: number): number[] {
    return Array.from({ length }, (_, index) => index);
  }

  /* 
  * books a seat
  */
  private async _bookSeat(seatId?: string): Promise<void> {
    if (!seatId) return

    this.isRequesting[seatId] = true

    let data = {}

    if(this.purchaseType == PurchaseType.FLEX) {
      data = {
        ...data,
        selectedFixtures: this._route.snapshot.queryParamMap.getAll('fixtures[]'),
      }
    }

    const res = await this._cartService.bookSeat(seatId, this.entityId, this.purchaseType, data as BookSeatData)
    delete this.isRequesting[seatId]

    this.isRequestBlocked = false
    this._cdr.detectChanges()

    /* handle error */
    if (res.error) {
      const errorHappenedText = this._translateService.instant('general.error-happened');
      const bookingSeatFailed = this._translateService.instant('stadium.seat.booking-seat-failed');
      
      this._alertService.error(errorHappenedText, res.error.error ?? res.error ?? bookingSeatFailed)
    }
  }

  /*
  * removes the seat from cart
  */
  private async _removeSeat(seatId?: any): Promise<void> {
    if (!seatId) return

    this.isRequesting[seatId] = true

    let data = {}

    let originalSeatId = seatId

    /* FLEX */
    if(this.purchaseType == PurchaseType.FLEX) {
      data = {
        ...data,
        selectedFixtures: this._route.snapshot.queryParamMap.getAll('fixtures[]'),
      }

      seatId = this.cart.items?.flex_tickets[this.flexId]?.flex_tickets[seatId]?.uuid
    }

    const res = await this._cartService.removeSeat(seatId, this.entityId, this.purchaseType, data as BookSeatData)
    
    delete this.isRequesting[originalSeatId]
    
    this.isRequestBlocked = false
    this._cdr.detectChanges()

    /* handle error */
    if (res.error) {
      const errorHappenedText = this._translateService.instant('general.error-happened');
      const removingSeatFailed = this._translateService.instant('stadium.seat.removing-seat-failed');

      this._alertService.error(errorHappenedText, res.error.error ?? res.error ?? removingSeatFailed)
    }
  }

  private get entityId() {
    /* fixture */
    if(this.purchaseType == PurchaseType.TICKET) {
      return this.fixtureId
    } 
    /* season pass */
    else if(this.purchaseType == PurchaseType.SEASON_PASS) {
      return this.seasonPassId
    } 

    /* flex */
    return this.flexId
  }

  public jumpToSector(event: any) {
    this.sectorClickedEvent.emit(event);
  }

  public identifySeat(index: number, item: Seat) {
    return item.id
  }

  public identify(index: any, item: SectorWithSeats) {
    return item.n
  }

  get sectors() {
    let array: Array<SectorWithSeats> = []

    if(!this.stadiumState?.sectors) return []

    Object.keys(this.stadiumState?.sectors ?? {}).map((value) => {
      array.push(this.stadiumState.sectors[value as any])
    })
    
    return array
  }

  isSectorSeated(sector: SectorWithSeats) {
    return sector.t == SectorTypes.SEATED
  }

  public sectorToShow = ''

  handleSectorClick(event: any, sector: SectorWithSeats) {
    /* special access modal */
    if(this.fixtureId) {
      if(this.sectorData[sector.i as keyof SectorData]?.has_special_access) {
        this._modalService.showSpecialAccessModal({fixtureSlug: this.fixtureId})
        return 
      }
    }

    this.sectorToShow = sector.i
    
    /* if seated then zoom */
    if (this.isSectorSeated(sector)) {
      this.jumpToSector(event)
      return;
    }

    /* if standing then modal */
    this._modalService.showStandingSectorTicketSelectorModal({ sector: sector })
  }

  public getNumberOfTicketsInSector(sectorId: string) {
    let numberOfTickets = 0

    if (!this.cart.items) return 0;

    let tickets = {} as any

    if(this.fixtureId) {
      tickets = this.cart.items?.events[this.fixtureId]?.tickets
    } else if (this.seasonPassId) {
      tickets = this.cart?.items?.season_passes[this.seasonPassId]?.season_passes
    } else if (this.flexId) {
      tickets = this.cart?.items?.flex_tickets[this.flexId]?.flex_tickets
    }

    if (!tickets || !Object.keys(tickets).length) return 0

    Object.keys(tickets).map((ticketId: string) => {
      const ticket = tickets[ticketId]
      
      if (ticket.sector_id == sectorId) {
        numberOfTickets++
      }
    })

    return numberOfTickets
  }

  public extractTranslation(transformString: string) {
    // Match the numbers within the translate function using regex
    const match = transformString.match(/translate\(([-+]?\d*\.?\d+),\s*([-+]?\d*\.?\d+)\)/);
    if (match) {
      // Extract translation values
      const translateX = parseFloat(match[1]);
      const translateY = parseFloat(match[2]);
      return { translateX, translateY };
    } else {
      return { translateX: 0, translateY: 0 }; // Return default values if no match
    }
  }

  public displaySeatsForSector(sectorId: string) {
    return sectorId == this.sectorToShow && this.scale >= this.scaleThreshold
  }

  public getSeatTooltipTitle(seat: Seat) {
    if(!seat.txt) {
      return seat.tl
    }
    
    return seat.tl + '<br>' + seat.txt
  }

  public getSeatTooltipContent(seat: Seat, sector:SectorWithSeats) {
    if(this.seatDetails?.[seat.id]) {
      return this.seatDetails[seat.id][0].seat_detail_text
    }

    return sector.tt?.content
  }

  public sectorTranslatedDataTitle(sector: SectorWithSeats) {
    const sectorText = this._translateService.instant('stadium.general.sector');

    return sectorText + ' ' + sector.n
  }

  get sectorNumberOfItemsText() {
    if(this.fixtureId) return this._translateService.instant('general.ticket')

    if(this.seasonPassId) return this._translateService.instant('general.season-pass')

    if(this.flexId) return 'FLEX jegy'

    return ''
  }

}
