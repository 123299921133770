import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Redirect } from 'src/app/enums/cart/checkout/redirect';
import { UserType } from 'src/app/enums/user/userType';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { Cart } from 'src/app/interfaces/State/Cart/cart';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CartService } from 'src/app/services/Cart/cart.service';
import { EncryptDecryptService } from 'src/app/services/EncryptDecryptService/encrypt-decrypt.service';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { Location } from '@angular/common'
import { take } from 'rxjs/operators';
import { FixtureType } from 'src/app/enums/fixture/fixtureType';
import { CartError } from 'src/app/enums/cart/errors';
import { ApiRequestService } from 'src/app/services/ApiRequest/api-request.service';
import { CookieService } from 'src/app/services/Cookie/cookie.service';
import { login, logout, setUser } from 'src/app/actions/auth.actions';
import { User } from 'src/app/interfaces/State/User/user';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { HelperService } from 'src/app/services/Misc/Helper/helper.service';
import { FlexTicket } from 'src/app/interfaces/State/CartItem/flex-ticket';
import { Tickets } from 'src/app/interfaces/State/CartItem/tickets';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {
  public auth: Auth = { } as Auth
  public cart: Cart = {} as Cart
  public mapOnly: boolean = false
  public checkoutForm  = this._formBuilder.group({
    paymentMethod: ['', [Validators.required]],
  });    
  public formSubmitAttempt: boolean = false
  public isRequesting: boolean = false
  public isRedirectingToPay: boolean = false
  public error: string = ""
  private _subscription?: Subscription

  constructor(
    private _store: Store,
    private _formBuilder: FormBuilder,
    private _alertService: AlertService,
    private _modalService: ModalService,
    private _cartService : CartService, 
    private _encryptDecryptService: EncryptDecryptService,
    private _router: Router,
    private location: Location,
    private _route: ActivatedRoute,
    private _apiRequestService: ApiRequestService,
    private _cookieService: CookieService,
    private _translateService: TranslateService,
    private _localizeRouterService: LocalizeRouterService,
    private _helperService: HelperService,
  ) {
    this.mapOnly = this._route.snapshot.queryParamMap.get('only-map') ? true : false

  }

  ngOnInit(): void {
    this.checkUserState()

    this._subscription = this._store.subscribe((state: any) => {
      this.auth = state.auth
      this.cart = state.cart
      /* remove / add payment provider validators */
      if(!this.cart.need_to_select_payment_provider) {
        this.checkoutForm.get('paymentMethod')?.removeValidators(Validators.required)
      } else {
        this.checkoutForm.get('paymentMethod')?.addValidators(Validators.required)
      }      
    })

    this._store
    .pipe(take(1))
    .subscribe((state: any) => {
      this.auth = state.auth
      
      if(!this.isCashier()) 
      {
        this.setValue('paymentMethod', 'simple_pay')
      }
    })
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }

  public fixtureTypes() {
    return FixtureType
  }

  public isFixtureTypes(fixture: any, eventType: string) {
    return eventType == fixture.event_type
  }

  hasCart(): boolean {
    return this.cart && this.cart.items && (this.cart.items.events || this.cart.items.season_passes || this.cart.items.flex_tickets) && (Object.keys(this.cart.items?.events)?.length || Object.keys(this.cart.items?.season_passes)?.length || Object.keys(this.cart.items?.flex_tickets)?.length) ? true : false
  }

  setValue(property: string, value: any) : void{
    this.checkoutForm.get(property)?.setValue(value)
  }

  getValue(property: string) {
    return this.checkoutForm.get(property)?.value
  }

  public isCashier(): boolean {
    return this.auth.user.type == UserType.CASHIER
  }

  navigateBack() {
    this.location.back()
  }

  reset(): void {
    this.checkoutForm.reset()
    this.formSubmitAttempt = false
  }

  async onSubmit() {
    if(this.isRequesting) return ;

    this.isRequesting = true
    this.formSubmitAttempt = true

    this.error = ""

    if(!this.checkoutForm.valid) {
      if(!this.checkoutForm.get('paymentMethod')?.valid) {
        const paymentMethodRequired = this._translateService.instant('cart. payment-method-required');
        const errorHappenedText = this._translateService.instant('general.error-happened');
        this._alertService.error(errorHappenedText, paymentMethodRequired)
      }
      this.isRequesting = false
      return ;
    }

    /* frontend validation */
    if(!this.cart.validation.can_checkout) {
      this.isRequesting = false

      if(this.cart.validation.error_code == CartError.CART_INVOICE_DETAILS_EMPTY) {
        this.showInvoiceDetailsModal()
        return;
      }
      const errorHappenedText = this._translateService.instant('general.error-happened');

      if(this.cart.validation.error_code == CartError.CART_NOT_MY_CART) {

        this._alertService.error(errorHappenedText, this.cart.validation.error_msg ?? '')
        this._apiRequestService.clearCart()
        return 
      }

      this._alertService.error(errorHappenedText, this.cart.validation.error_msg ?? '')
      return ;
    }
    
    const res = await this._cartService.checkout(this.checkoutForm.get('paymentMethod')?.value!, {onlyMap : this.mapOnly})

    this.isRequesting = false

    if(res.status) {
      const errorHappenedText = this._translateService.instant('general.error-happened');
      this._alertService.error(errorHappenedText, res.error)
      return ;
    }

    this.isRedirectingToPay = true

    /* on site navigation */
    if(res.data.redirect == Redirect.ONSITE) {
      const url = this._helperService.translateRoute(`order/${res.data.order_id}`)

      this._router.navigate([url]);
      return ;
    } 

    /* off site navigation */
    if(res.data.redirect == Redirect.OFFSITE) {
      window.location.href = res.data.url
    }
  }


  get actionButtonText() {

    if(this.isRedirectingToPay) {
      const redirectingText = this._translateService.instant('cart.redirecting');

      return redirectingText
    }

    const payText = this._translateService.instant('cart.pay');
    return payText
  }

  hideModal() {
    this._modalService.hideModal()
  }

  showInvoiceDetailsModal() {
    this._modalService.showCartInvoiceModal()
  }

  isFieldInvalid(field: string, errorType: string = '') {
    return !this.checkoutForm.get(field)!.valid && 
    this.formSubmitAttempt &&
    (errorType == '' || this.checkoutForm?.get(field)?.errors?.[errorType])
  }

  isFieldInvalidAtAll(field: string) {
    return !this.checkoutForm.get(field)!.valid && this.formSubmitAttempt
  }

  get invoiceDetails() {
    return this.cart.details.invoice_details
  }

  get invoiceDetailsFormatted() {
    return this.cart.details.invoice_details?.formatted?.map(value => {
      if(value.indexOf(',') === -1) {
        return this._encryptDecryptService.decrypt(value)
      }
      
      return value.split(',').map(v => {
        return this._encryptDecryptService.decrypt(v.trim())
      }).join(', ')
      
    })
  }


  private async checkUserState() {
    const token = this._cookieService.getCookie('access_token')
    /* if token exists */
    if(token) {
      try {
        const res = await this._apiRequestService.getRequest('me')
        this._store.dispatch(login())
        this._store.dispatch(setUser({user: {...res.data as User}}))
      } catch (e) {
        this._store.dispatch(logout())
        this._store.dispatch(setUser({ user: {} as User }))
      }
    } else {
      this._store.dispatch(logout())
      this._store.dispatch(setUser({ user: {} as User }))
    }
  }

  getFirstOpponent(flexTickets: any): string {
    if (flexTickets.value && Object.keys(flexTickets.value).length > 0) {
      const firstEntry = Object.entries(flexTickets.value.flex_tickets  as Tickets)[0];
      return firstEntry[1]?.details?.opponents || 'N/A';
    }
    return 'N/A';
  }
}
