import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { Index as MyFlexTicketPurchaseIndex} from 'src/app/interfaces/State/MyFlexTickets/index';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { SpinnerComponent } from '../assets/icons/spinner/spinner.component';
import { ButtonModule } from '../form/button/button.module';
import { MyTicketService } from 'src/app/services/MyTicket/my-ticket.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { TransferService } from 'src/app/services/Transfer/transfer.service';
import { Ticket } from 'src/app/interfaces/State/MyTickets/ticket';
import { UserType } from 'src/app/enums/user/userType';
import { FixtureType } from 'src/app/enums/fixture/fixtureType';
import { FlexTicket } from 'src/app/interfaces/State/CartItem/flex-ticket';
import { SingleFlexTicket } from 'src/app/interfaces/State/Flex/SingleFlexTicket';

@Component({
  selector: 'app-my-flex-ticket',
  standalone: true,
  imports: [CommonModule, RouterModule, SpinnerComponent, ButtonModule,],
  templateUrl: './my-flex-ticket.component.html',
  styleUrls: ['./my-flex-ticket.component.scss']
})
export class MyFlexTicketComponent {
  @Input() flexTicketPurchaseObject: MyFlexTicketPurchaseIndex = {} as MyFlexTicketPurchaseIndex
  @Input() auth: Auth = {} as Auth
  @Input() isInvoiceIssued?: Boolean = false

  public downloading: Array<string> = []
  public printing: Array<number> = []
  public pdfUrl?: string
  public ticketUrl?: string
  public showBackToMatch: Boolean = false
  public mapOnly: boolean = false
  error: string = ''
  success: boolean = false
  isRequesting: Array<string> = []

  constructor(
    private _myTicketService: MyTicketService,
    private sanitizer: DomSanitizer,
    private _route: ActivatedRoute,
    private _alertService: AlertService,
    private _transferService: TransferService,
  ) {
    this.showBackToMatch = this._route.snapshot.queryParamMap.get('only-map') ? false : true
    this.mapOnly = this._route.snapshot.queryParamMap.get('only-map') ? true : false
  }

  public invoiceUrl(ticket: SingleFlexTicket) {
    let queryString = ''

    if (this.mapOnly) {
      queryString = '?only-map=1'
    }

    return '/megrendeles/' + ticket.order_id + '/szamla' + queryString
  }

  public isCustomer(): boolean {
    return this.auth.user.type == UserType.CUSTOMER
  }

  public async getAppleWalletPass(seatId: number) {
    //const res = await this._myTicketService.getAppleWalletPass(this.fixtureWrapperObject.fixture.slug, seatId)
  }

  public mobileTicketUrl(ticket: any) {
    return '/jegyeim/merkozes/' + ticket.fixture.slug + '/jegy/' + ticket.uuid
  }

  get queryString() {
    return this._route.snapshot.queryParams
  }

  public isDownloading(ticket: SingleFlexTicket) {
    return this.downloading.includes([ticket.fixture.slug, ticket.seat_id].join('-'))
  }

  public isPrinting(seatId: number) {
    return this.printing.includes(seatId)
  }

  async rekove(ticket: SingleFlexTicket) {
    if (this.isRequesting.includes(ticket.uuid!)) return;
  }

  public isCashier(): boolean {
    return this.auth.user.type == UserType.CASHIER
  }

  public async downloadTicket(ticket: SingleFlexTicket, isMobile: boolean = false) {

    if (this.downloading.includes([ticket.fixture.slug,ticket.seat_id].join('-'))) return;

    this.downloading.push([ticket.fixture.slug,ticket.seat_id].join('-'))

    try {
      const res = await this._myTicketService.downloadTicket(ticket.fixture.slug, ticket.seat_id)

      const index = this.downloading.indexOf([ticket.fixture.slug,ticket.seat_id].join('-'), 0);
      if (index > -1) {
        this.downloading.splice(index, 1);
      }

      if (res.success) {
        if (isMobile) {
          this.pdfUrl = res.data.url
        } else {
          window.open(res.data.url, '_blank');
        }
      }
    } catch (e: any) {
      this._alertService.error(
        'Hiba történt!',
        e.error.error ?? '',
      )

      const index = this.downloading.indexOf([ticket.fixture.slug, ticket.seat_id].join('-'), 0);
      if (index > -1) {
        this.downloading.splice(index, 1);
      }
    }

  }

  public async printTicket(seatId: number) {
    if (this.auth.user?.type !== UserType.CASHIER) return;

    if (this.printing.includes(seatId)) return;

    if (!this.auth.user.printer?.address?.length) return;

    this.printing.push(seatId);

    /*const ticketData = this.fixtureWrapperObject.tickets.find(v => v.seat_id === seatId);

    if (!ticketData) return;

    await this._myTicketService.printTicket(ticketData, this.fixtureWrapperObject.fixture.teams, this.auth.user.printer.address);

    const index = this.printing.indexOf(seatId, 0);
    if (index > -1) {
      this.printing.splice(index, 1);
    }*/
  }

  public isFixtureTypes(fixture: any, eventType: string) {
    return eventType == fixture.event_type
  }


  public fixtureTypes() {
    return FixtureType
  }


}
