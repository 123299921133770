import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { Store } from '@ngrx/store';
import { FlexService } from 'src/app/services/Flex/flex.service';
import { FlexRule } from 'src/app/interfaces/State/Flex/FlexRule';
import { FlexFixture } from 'src/app/interfaces/State/Flex/FlexFixture';
import { FlexFixtureFixture } from 'src/app/interfaces/State/Flex/FlexFixtureFixture';
import { FlexFixtureCategory } from 'src/app/enums/flex/flexFixture/flexFixtureCategory';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CookieService } from 'src/app/services/Cookie/cookie.service';
import { Router } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HelperService } from 'src/app/services/Misc/Helper/helper.service';
import { CheckComponent } from 'src/app/components/assets/icons/check/check.component';

@Component({
  selector: 'app-flex',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule,
    LocalizeRouterModule,
    CheckComponent,
  ],
  templateUrl: './flex.component.html',
  styleUrls: ['./flex.component.scss']
})
export class FlexComponent {
  public isLoadingInitially: boolean = true
  public isRequesting: boolean = false
  public flexRules?: Array<FlexRule>
  public flexFixtures?: Array<FlexFixture>
  public selectedFixtures: Array<FlexFixture> = []
  public redirecting: boolean = false
  public static cookieKey: string = 'flex-selected-fixtures'

  constructor(
    private _flexService: FlexService,
    private _alertService: AlertService,
    private _cookieService: CookieService,
    private _helperService : HelperService,
    private _translateService: TranslateService,
    private _router: Router,
  ) {
  }

  public async ngOnInit()
  {
    const res = await this._flexService.getFlex()
    this.flexRules = res.data['flex-rules']
    this.flexFixtures = res.data['flex-fixtures']
    this.isLoadingInitially = false
  }

  public async next()
  {
    /* if can not be continued */
    if(!this.canBeContinued()) {
      const selectionErrorText = this._translateService.instant('flex.selection-error');
      this._alertService.error("", selectionErrorText);
      return ;
    }

    /* redirecting */
    this.redirecting = true

    /* selected fixtures array */
    const selectedFixturesArray = this.selectedFixtures.map((fixture: FlexFixture) => {
      return fixture.fixture.id
    })

    try {
      await this._flexService.validateSelection(this.getValidFlex()?.uuid!, selectedFixturesArray)
    } catch (e: any) {
      const errorHappenedText = this._translateService.instant('general.error-happened');

      this._alertService.error(errorHappenedText, e.error.error ?? '')
      this.redirecting = false
      return;
    }

    const flexUrl = this._helperService.translateRoute("flex/" + this.getValidFlex()?.uuid)

    this._router.navigate([flexUrl], {
      queryParams: { 'fixtures[]': selectedFixturesArray }
    });
  }

  public unselect(fixtureSlug:string)
  {
    this.selectedFixtures = this.selectedFixtures.filter(item => item.fixture.id !== fixtureSlug);
  }

  public select(fixture: FlexFixture)
  {
    this.selectedFixtures.push(fixture)
  }

  public isSelected(fixtureSlug: string)
  {
    return this.selectedFixtures.find(fixture => fixture.fixture.id == fixtureSlug)
  }

  public lessSelectedFixtures(rule: FlexRule): Boolean
  {
    return rule.no_matches > this.selectedFixtures.length
  }

  public validFixtureSelection(rule: FlexRule): Boolean
  {
    if(rule.no_matches != this.selectedFixtures.length) return false

    const numberOfNormalFixturesToSelect = rule.no_normal_matches
    const numberOfPremiumFixturesToSelect = rule.no_premium_matches

    const normalFixtures = this.selectedFixtures.filter((fixture) => fixture.category == FlexFixtureCategory.NORMAL)
    const premiumFixtures = this.selectedFixtures.filter((fixture) => fixture.category == FlexFixtureCategory.PREMIUM)

    return numberOfNormalFixturesToSelect <= normalFixtures.length && numberOfPremiumFixturesToSelect <= premiumFixtures.length
  }

  public invalidFixtureSelection(rule: FlexRule): Boolean 
  {
    return rule.no_matches == this.selectedFixtures.length && !this.validFixtureSelection(rule)
  }

  public isPremium(fixture: FlexFixture)
  {
    return fixture.category == FlexFixtureCategory.PREMIUM
  }

  public canBeContinued(): Boolean
  {
    return this.flexRules?.find((rule) => {
      return this.validFixtureSelection(rule)
    }) ? true : false
  }

  public getValidFlex() 
  {
    return this.flexRules?.find((rule) => {
      return this.validFixtureSelection(rule)
    })
  }

  public client()
  {
    return this._helperService.getClient()
  }
}
